import React, { useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Loader from "../Loader";
import Wrapper from "../Dashboard/DashboardWrapper";
import { useLoading, useLocalStorage } from "../../hooks";
import Header from "../Dashboard/Header";
import MainWrapper from "../MainWrapper";
import {
  all_strategies,
  months_of_year,
  some_years,
  strategy_direction,
  style_select_picker,
} from "../../utilities";
import Select from "react-dropdown-select";
import { AddPerformance } from "../../API";
import { toast } from "react-toastify";

const picker_props = {
  style: style_select_picker,
  labelField: "name",
  valueField: "id",
  dropdownHeight: "110px",
  separator: false,
  backspaceDelete: false,
  keepSelectedInList: true,
  className: "min-w-40",
};

function Index() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [performance, setPerformance] = useState({});
  const [authToken] = useLocalStorage("authToken", "");

  const addPerformance = async () => {
    startLoading();
    const res = await AddPerformance(performance, authToken);
    console.log(res);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };
  return (
    <MainWrapper>
      <Sidebar active={{ id: "6", href: "/data-room" }} />
      <div className="max-sm:hidden">
        <Header />
      </div>
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div
          onClick={() => window.open(`/bots-management`, "_self")}
          className="flex items-center gap-4 cursor-pointer w-[15%] py-2"
        >
          <span className="font-semibold whitespace-nowrap">
            New performance
          </span>
        </div>
        <div className="bg-[#151924] py-8 px-6 rounded-xl flex flex-col gap-6 text-gray-400">
          <div className="flex justify-between max-sm:items-end">
            <span>Strategy</span>
            <Select
              {...picker_props}
              options={all_strategies}
              labelField="id"
              onChange={(values) => {
                setPerformance((performance) => ({
                  ...performance,
                  strategy_id: values[0].id,
                }));
              }}
            />
          </div>
          <div className="flex justify-between max-sm:items-end">
            <span>Year</span>
            <Select
              {...picker_props}
              options={some_years}
              labelField="id"
              onChange={(values) => {
                setPerformance((performance) => ({
                  ...performance,
                  year: values[0].id,
                }));
              }}
            />
          </div>
          <div className="flex justify-between max-sm:items-end">
            <span>Month</span>
            <Select
              {...picker_props}
              options={months_of_year}
              labelField="id"
              onChange={(values) => {
                setPerformance((performance) => ({
                  ...performance,
                  month: values[0].id,
                }));
              }}
            />
          </div>
          <div className="flex justify-between max-sm:items-end">
            <span>Strategy direction</span>
            <Select
              {...picker_props}
              options={strategy_direction}
              labelField="id"
              onChange={(values) => {
                setPerformance((performance) => ({
                  ...performance,
                  strategy_direction: values[0].id,
                }));
              }}
            />
          </div>
          <div className="flex justify-between max-sm:mt-5">
            <span>Deals completed</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  deals_completed: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>
          <div className="flex justify-between">
            <span>Performance</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  performance: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>
          <div className="flex justify-between">
            <span>Max. days in deal</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  maximum_days_in_a_deal: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>
          <div className="flex justify-between">
            <span>Avg. days in deal</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  average_days_in_a_deal: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>
          <div className="flex justify-between">
            <span>Max. price_deviation</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  max_price_deviation: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>
          <div className="flex justify-between">
            <span>TV. Chart link</span>
            <input
              onChange={(e) => {
                setPerformance((performance) => ({
                  ...performance,
                  trading_view_link: e.target.value,
                }));
              }}
              placeholder=""
              className="bg-transparent border-b focus:outline-none focus:ring-0 border-border"
            />
          </div>

          <div
            onClick={addPerformance}
            className="self-end px-4 py-1 mt-8 rounded-md cursor-pointer text-slate-50 bg-green-light max-sm:py-2"
          >
            Save changes
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Index;
