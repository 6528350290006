import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import Modal from "../Modal";
import useDisclosure from "../../hooks/useDisclosure";
import DebouncedInput from "../DebouncedInput";
import useFuzzy from "../../hooks/useFuzzy";
import { style_select_picker } from "../../utilities";
import { FetchBots, MarginCall, FillBotIntent, SendInvoices } from "../../API";
import { formatDate } from "../../utilities";

function Main() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [bots, setBots] = useState([]);
  const [unfilteredBots, setUnfilteredBots] = useState([]);
  const [filter, setFilter] = useState("");
  const [marginCall, setMarginCall] = useState();
  const [checked, setChecked] = useState([]);
  const [endDate, setEndDate] = useState(new Date());

  const { isOpen, onClose, onOpen, onToggle, setOpen } = useDisclosure();
  const {
    isOpen: isOpen1,
    onClose: onClose1,
    onOpen: onOpen1,
    onToggle: onToggle1,
    setOpen: setOpen1,
  } = useDisclosure();

  const { search } = useFuzzy(unfilteredBots, {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["strategy", "exchange", "name", "intent"],
  });

  const clickTable = (botId, userId) => {
    window.open(
      `/invoice/bot-invoices?bot_id=${botId}&user_id=${userId}`,
      "_self"
    );
  };

  const fetchBots = async () => {
    startLoading();
    const res = await FetchBots(authToken);
    if (res?.success) {
      const botRes = res.bots.sort((a, b) => a.user.name - b.user.name);

      setBots(botRes);
      setUnfilteredBots(botRes);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const sendInvoices = async (status) => {
    if (checked.length < 1) return;
    startLoading();

    const res = await SendInvoices(authToken, {
      bots: checked,
      end_date: endDate,
    });

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setChecked([]);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleMarginCall = async (status) => {
    if (checked.length < 1) return;
    startLoading();

    const res = await MarginCall(
      {
        bots: checked.map((id) => ({ _id: id, status })),
      },
      authToken
    );

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setChecked([]);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchBots();
    }
  }, []);

  useEffect(() => {
    if (checked.length > 0) {
      onOpen1();
    }
  }, [marginCall]);

  useEffect(() => {
    if (filter) {
      const filterRes = search(filter);
      setBots(filterRes.map((data) => data.item));
      setChecked([]);
    } else {
      setBots(unfilteredBots);
    }
  }, [filter]);

  const handleSellectAll = (e) => {
    if (e.target.checked) {
      setChecked(bots.map((item) => item._id));
    } else {
      setChecked([]);
    }
  };

  const handleDone = async (e, bot_id) => {
    e.stopPropagation();
    startLoading();
    const res = await FillBotIntent({ bot_id }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      fetchBots();
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  return (
    <MainWrapper>
      <Sidebar active={{ id: "4", href: "/invoice" }} />
      <div className="max-sm:hidden">
        <Header />
      </div>
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div className="bg-[#151924] py-8 px-6 rounded-xl overflow-x-auto">
          <div className="flex flex-col w-full gap-5 mb-5 sm:items-center sm:flex-row">
            <div className="flex items-center sm:justify-center max-sm:hidden">
              <input
                className=""
                type="checkbox"
                name=""
                id=""
                onChange={(e) => handleSellectAll(e)}
                checked={checked.length > 0 && checked.length === bots.length}
              />
              <p className="">Select All</p>
            </div>

            <input
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
              className="bg-[#d9d9d9] text-black px-2 max-sm:w-1/2 p-2"
            />
            {/* <Select
              //disabled={item.intent == "FULFILLED" ? true : false}
              style={style_select_picker}
              options={[
                {
                  id: "1",
                  name: "Margin Call Enabled",
                  status: true,
                },
                {
                  id: "2",
                  name: "Margin Call Disabled",
                  status: false,
                },
              ]}
              labelField="name"
              valueField="id"
              values={marginCall}
              placeholder="Margin Call"
              dropdownHeight={"110px"}
              separator={false}
              onChange={(values) => {
                setMarginCall(values[0]);
              }}
              // backspaceDelete={false}
              // keepSelectedInList={true}
            /> */}

            <button
              // disabled={item.intent === "NIL"}
              onClick={onOpen}
              className={`bg-[#d9d9d9] text-black px-4 py-2 rounded-md text-md w-fit max-sm:text-sm`}
            >
              Send Invoice
            </button>

            <div className="flex items-end gap-5">
              <div className="flex flex-col justify-center gap-3 sm:items-center sm:flex-row">
                <p className="">Filter:</p>
                <DebouncedInput
                  type="text"
                  name=""
                  id=""
                  color=""
                  className="outline-none px-3 text-[#151924] w-[200px] h-[30px] rounded-lg"
                  onChange={(value) => setFilter(value)}
                  value={filter}
                />
              </div>

              <div className="flex items-center sm:justify-center sm:hidden">
                <input
                  className=""
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) => handleSellectAll(e)}
                  checked={checked.length > 0 && checked.length === bots.length}
                />
                <p className="">Select All</p>
              </div>
            </div>
          </div>

          <div className="w-full max-sm:overflow-x-scroll">
            <table className="min-w-full rounded-lg bg-primary">
              <thead>
                <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                  <th className="py-4 text-left px1">{""}</th>
                  <th className="px-4 py-4 text-left">User</th>
                  <th className="px-4 py-4 text-left">Last Snapshot</th>
                  <th className="px-4 py-4 text-left">Strategy</th>
                  <th className="px-4 py-4 text-left">Status</th>
                  <th className="px-4 py-4 text-left">Exchange</th>
                </tr>
              </thead>

              <tbody>
                {bots.length > 0 ? (
                  bots.map((item) => (
                    <tr
                      className="cursor-pointer whitespace-nowrap"
                      key={item._id}
                    >
                      <td className="px-4 py-4">
                        <div>
                          <input
                            id="checkbox"
                            onChange={(e) => {
                              setChecked((prevChecked) => {
                                if (e.target.checked) {
                                  // Add item if it's checked and not already in the array
                                  if (!prevChecked.includes(item._id)) {
                                    return [...prevChecked, item._id];
                                  }
                                } else {
                                  console.log("unchecked");
                                  // Remove item if it's unchecked
                                  return prevChecked.filter(
                                    (id) => id !== item._id
                                  );
                                }
                                return prevChecked;
                              });
                            }}
                            type="checkbox"
                            checked={checked.includes(item._id)}
                          />
                        </div>
                      </td>
                      <td
                        onClick={() => clickTable(item._id, item.user._id)}
                        className="px-4 py-4"
                      >
                        {item.user?.name}
                      </td>
                      <td
                        onClick={() => clickTable(item._id, item.user._id)}
                        className="px-4 py-4"
                      >
                        <div className="flex gap-3">
                          <span className="">
                            {formatDate(item.lastSnapshot, "MMM DD, YYYY")}
                          </span>
                          <span className="">
                            {formatDate(item.lastSnapshot, "hh:mm A")}
                          </span>
                        </div>
                      </td>
                      <td
                        onClick={() => clickTable(item._id, item.user._id)}
                        className="px-4 py-4"
                      >
                        {item.strategy}
                      </td>
                      <td
                        onClick={() => clickTable(item._id, item.user._id)}
                        className="px-4 py-4"
                      >
                        {item.status == "ACTIVE" ? "Running" : "Stopped"}
                      </td>

                      <td
                        onClick={() => clickTable(item._id, item.user._id)}
                        className="px-4 py-4"
                      >
                        {item.exchange}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="py-12 mt-20 font-semibold text-center opacity-50 sm:text-lg font-public-sans"
                    >
                      Nothing is here yet!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          closeModal={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onToggle={onToggle}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to send send an invoice call to{" "}
              {checked.length} accounts?
            </p>
            <div className="flex items-center justify-evenly">
              <button
                onClick={() => {
                  sendInvoices();
                  onClose();
                }}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
