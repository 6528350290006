import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import front_arrow from "../../assets/icons/front-arrow.png";
import { toast } from "react-toastify";
import { FetchUserInvoices } from "../../API";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../utilities";

const BotInvoices = () => {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");

  const [invoices, setInvoices] = useState([]);

  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const user_id = queryParams.get("user_id");
  const bot_id = queryParams.get("bot_id");

  const fetchUserInvoices = async () => {
    startLoading();
    const res = await FetchUserInvoices(authToken, user_id, bot_id);
    if (res?.success) {
      const invoicesRes = res.invoices.sort(
        (a, b) => new Date(b.lastSnapshot) - new Date(a.lastSnapshot)
      );
      setInvoices(invoicesRes);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchUserInvoices();
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "4", href: "/invoice" }} />
      <div className="max-sm:hidden">
        <Header />
      </div>
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div
          onClick={() => window.open(`/invoice`, "_self")}
          className="flex items-center gap-4 cursor-pointer w-[15%] py-2 mb-10"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold whitespace-nowrap">All Bots</span>
        </div>

        <div className="w-full max-sm:overflow-x-scroll">
          <table className="min-w-full rounded-lg bg-primary">
            <thead>
              <tr className=" whitespace-nowrap">
                <th className="px-4 py-4 text-left">User</th>
                <th className="px-4 py-4 text-left">Strategy</th>
                <th className="px-4 py-4 text-left">Invoice Date</th>
                <th className="px-4 py-4 text-left">Status</th>
                <th className="px-4 py-4 text-left">Amount</th>
                <th className="px-4 py-4 text-left">Currency</th>
                <th className="px-4 py-4 text-left">Tx ID Confirmation</th>
              </tr>
            </thead>

            <tbody>
              {invoices.length > 0 ? (
                invoices.map((item) => (
                  <tr className="cursor-pointer whitespace-nowrap" key={item._id}>
                    <td className="px-4 py-4">{item.user?.name}</td>
                    <td className="px-4 py-4">{item.bot.strategy}</td>
                    <td className="px-4 py-4">
                      <div className="flex gap-3">
                        <span className="">
                          {formatDate(item.to, "MMM DD, YYYY")}
                        </span>
                        <span className="">{formatDate(item.to, "hh:mm A")}</span>
                      </div>
                    </td>

                    <td className="px-4 py-4">{item.status}</td>
                    <td className="px-4 py-4">{item.amount}</td>
                    <td className="px-4 py-4">{item.currency}</td>
                    <td className="px-4 py-4">{item.exchange}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={7}
                    className="py-12 mt-20 font-semibold text-center opacity-50 sm:text-lg font-public-sans"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Wrapper>
    </MainWrapper>
  );
};

export default BotInvoices;
