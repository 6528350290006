import React, { useEffect, useState } from "react";
import { useAppContext } from "../../Context";
import logo from "../../assets/images/logo.svg";
import { useLocalStorage } from "../../hooks";

const tabs = [
  { id: "1", label: "Notifications", href: "/notifications" },
  {
    id: "2",
    label: "Bot Management",
    href: "/bots-management",
  },
  {
    id: "3",
    label: "Bot Activation Requests",
    href: "/bots-activation",
  },
  {
    id: "4",
    label: "Invoice Center",
    href: "/invoice",
  },
  { id: "5", label: "Payout Center", href: "/payout" },
  { id: "6", label: "Data Room", href: "/data-room" },
  { id: "7", label: "Sign out", href: "/sign-out" },
];


export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    // Update the width when the component mounts
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set the initial width
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};




function Sidebar({ active }) {
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [menuVisible, setMenuVisible] = useState(false)
  const width = useWindowWidth();

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  } 

  useEffect(() => {
    if(width >= 1280){
      setMenuVisible(true);
    }else{
      setMenuVisible(false)
    }
  }, [width])

  return (
    <>
        <section className="flex justify-between p-5 xl:hidden">
          <div className="flex items-center gap-4 opacity-100">
            <img className="w-4" src={logo} alt="logo" />
            <span className="text-lg font-semibold ">Admin Area</span>
          </div>
          <button onClick={handleMenuToggle} className="bg-[#292C3C] px-5 py-2 rounded">
            {menuVisible ? <span className="">
                Hide Menu
            </span> :
            <span className="">
                Show Menu
            </span>}
            
          </button>
        </section>
        <div className={`fixed top-0 flex z-50 flex-col min-h-screen gap-2 max-xl:p-5 xl:mx-5 xl:mt-6 bg-[#292C3C] xl:bg-transparent ${menuVisible ? 'translate-x-0': '-translate-x-[500px]'} `}>
          <div className="flex items-center gap-4 py-4 opacity-100">
            <img className="w-4" src={logo} alt="logo" />
            <span className="text-lg font-semibold ">Admin Area</span>
          </div>
          {tabs.map((item) => (
            <div key={item.id} className={`py-2 flex flex-col opacity-60 `}>
              <div
                onClick={() => {
                  if (item.href === "/sign-out") {
                    setAuthToken("");
                    window.open("/", "_self");
                  } else {
                    window.open(item.href, "_self");
                  }
                }}
                className="text-lg font-semibold cursor-pointer"
              >
                {item.label}
              </div>
              {item.id == active.id && (
                <div className="border-b-[0.8px] ] mt-2 w-10" />
              )}
            </div>
          ))}
        </div>
    </>
  );
}

export default Sidebar;
