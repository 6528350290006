import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import btc_pay from "../../assets/images/btc_pay.png";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { maskString, style_select_picker } from "../../utilities";
import copy_icon from "../../assets/icons/copy.png";
import { FetchWithdrawal, UpdateWithdrawal } from "../../API";

function Main() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [withdrawals, setwithdrawals] = useState([]);

  const copyTextToClipboard = (value, feedback) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.success(feedback + " copied", {
          position: "top-center",
        });
        // Optionally, you can provide feedback to the user that the text has been copied
      })
      .catch((error) => {
        console.error("Error copying text:", error);
        // Handle any errors that may occur during copying
      });
  };

  const fetchWithdrawal = async () => {
    startLoading();
    const res = await FetchWithdrawal(authToken);
    if (res?.success) {
      setwithdrawals(res.withdrawals);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const updateWithdrawal = async (status, item) => {
    startLoading();
    const res = await UpdateWithdrawal(
      { status: status[0].name ?? status, withdrawal_id: item._id },
      authToken
    );
    if (res?.success) {
      fetchWithdrawal();
      toast.success("Success", { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchWithdrawal();
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "5", href: "/payout" }} />
     <div className="max-sm:hidden">
      <Header />
     </div>
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="bg-[#151924] py-8 px-6 rounded-xl overflow-x-auto">
          <table className="min-w-full rounded-lg bg-primary">
            <thead>
              <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                <th className="px-4 py-4 text-left">User</th>
                <th className="px-4 py-4 text-left">Status</th>
                <th className="px-4 py-4 text-left">Date created</th>
                <th className="px-4 py-4 text-left">Amount</th>
                <th className="px-4 py-4 text-left">Currency</th>
                <th className="px-4 py-4 text-left">Wallet address</th>
                <th className="px-4 py-4 text-left">Action </th>
              </tr>
            </thead>

            <tbody>
              {withdrawals.length > 0 ? (
                withdrawals
                  .filter((item) => item.status !== "PAID")
                  .map((item, index) => (
                    <tr className="whitespace-nowrap" key={item}>
                      <td className="px-4 py-4">{item.user.name}</td>
                      <td className="px-4 py-4">{item.status}</td>
                      <td className="px-4 py-4">{item.createdAt}</td>

                      <td className="px-4 py-4 ">
                        <div className="flex items-center gap-2">
                          <span>{item.amount}</span>
                          <img
                            onClick={() =>
                              copyTextToClipboard(item.amount, "Amount")
                            }
                            src={copy_icon}
                            className="w-4 cursor-pointer"
                            alt=""
                          />
                        </div>
                      </td>

                      <td className="px-4 py-4">
                        {item.currency.toUpperCase()}
                      </td>

                      <td className="px-4 py-4 ">
                        <div className="flex items-center gap-2">
                          <span>{maskString(item.walletAddress)}</span>

                          <img
                            onClick={() =>
                              copyTextToClipboard(
                                item.walletAddress,
                                "Wallet address"
                              )
                            }
                            src={copy_icon}
                            className="w-4 cursor-pointer"
                            alt=""
                          />
                        </div>
                      </td>

                      {/* <td className="px-4 py-4">
                      <Select
                        style={style_select_picker}
                        options={[
                          {
                            id: "1",
                            name: "Pending",
                          },
                          { id: "2", name: "Approved" },
                        ]}
                        labelField="name"
                        valueField="id"
                        values={[
                          {
                            id: item.status == "PAID" ? "2" : "1",
                            name:
                              item.status == "PAID" ? "Approved" : "Pending",
                          },
                        ]}
                        dropdownHeight={"110px"}
                        separator={false}
                        onChange={(values) => {
                          updateWithdrawal(values, item);
                        }}
                        backspaceDelete={false}
                        keepSelectedInList={false}
                      />
                      <p className="">
                        {item.status === "UNPAID" ? "PENDING" : "PAYMENT SENT"}
                      </p>
                    </td> */}

                      <td className="px-4 py-4">
                        <button
                          disabled={item.status === "PAID"}
                          onClick={() => updateWithdrawal("Approved", item)}
                          className={`bg-[#d9d9d9] text-black ${
                            item.status === "PAID" &&
                            "opacity-50 cursor-not-allowed"
                          }  px-4 py-2 rounded-md text-md`}
                        >
                          DONE
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="py-12 mt-20 font-semibold text-center opacity-50 sm:text-lg font-public-sans"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
