import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import MainWrapper from "../MainWrapper";
import Logo from "../../assets/images/logo.svg";

function index() {
  return (
    <MainWrapper>
      <div className="flex flex-col items-center justify-center w-full min-h-screen gap-10 max-sm:p-5">
        <img className="w-32 h-32 rounded-full" src={Logo} alt="" />
        <Main />
      </div>
    </MainWrapper>
  );
}

export default index;
