let apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4023/admin"
    : "https://api.cryptobots.io/admin";

export const SignIn = async (body) => {
  console.log(apiUrl, "apiUrl");

  return await fetch(apiUrl + `/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUser = async ({ user_id, authToken }) => {
  return await fetch(apiUrl + `/fetch-user?user_id=${user_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const SendInvoices = async (authToken, body) => {
  return await fetch(apiUrl + `/send-invoices`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBots = async (authToken) => {
  return await fetch(apiUrl + `/fetch-bots`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUserBots = async (user_id, authToken) => {
  return await fetch(apiUrl + `/fetch-user-bots?user_id=${user_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBot = async (authToken, _id) => {
  return await fetch(apiUrl + `/fetch-bot?_id=${_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchNotifications = async (authToken) => {
  return await fetch(apiUrl + `/fetch-notifications`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchWithdrawal = async (authToken) => {
  return await fetch(apiUrl + `/fetch-withdrawal`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateWithdrawal = async (body, authToken) => {
  return await fetch(apiUrl + `/update-withdrawal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const ActivateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/activate-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/update-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const DeleteBot = async (body, authToken) => {
  return await fetch(apiUrl + `/delete-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const MarginCall = async (body, authToken) => {
  return await fetch(apiUrl + `/margin-call`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const AddPerformance = async (body, authToken) => {
  return await fetch(apiUrl + `/add-bot-history`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const DisableBot = async (body, authToken) => {
  return await fetch(apiUrl + `/disable-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const NotifyUser = async (body, authToken) => {
  return await fetch(apiUrl + `/notify-user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FillBotIntent = async (body, authToken) => {
  return await fetch(apiUrl + `/fill-request`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
export const SetBotIntent = async (body, authToken) => {
  return await fetch(apiUrl + `/set-bot-intent`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const CustomNotification = async (body, authToken) => {
  return await fetch(apiUrl + `/custom-notification`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const BanUser = async (body, authToken) => {
  return await fetch(apiUrl + `/ban-user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchReferral = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-referral`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchTransactions = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-transactions`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchInvoices = async (authToken) => {
  return await fetch(apiUrl + `/fetch-invoices`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
export const FetchUserInvoices = async (authToken, user_id, bot_id) => {
  return await fetch(
    apiUrl + `/fetch-user-invoices?user_id=${user_id}&bot_id=${bot_id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUserReferrals = async (user_id, authToken) => {
  return await fetch(apiUrl + `/fetch-user-referrals?_id=${user_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateUserReferredBy = async (body, authToken) => {
  return await fetch(apiUrl + `/update-user-referred-by`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
